<template>
    <card>
        <form @submit.prevent="guardarPlantillaRuta">
            <div class="row mt-4 align-items-center">
                <div class="col-12 mb-3">
                    <div>
                        <label for="lugarEntrega" class="form-label">
                            <em class="text-danger font-weight-bolder">Paso 1</em> - Lugar de Entrega
                        </label>
                        <input v-model="plantillaRuta.LugarEntrega" type="text" id="lugarEntrega" class="form-control"
                            required>
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <div>
                        <label for="latUbicacionEntrega" class="form-label">
                            <em class="text-danger font-weight-bolder">Paso 2</em> - Latitud
                        </label>
                        <input v-model="plantillaRuta.LatUbicacionEntrega" type="text" step="any"
                            id="latUbicacionEntrega" class="form-control" required>
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <div>
                        <label for="longUbicacionEntrega" class="form-label">
                            <em class="text-danger font-weight-bolder">Paso 3</em> - Longitud
                        </label>
                        <input v-model="plantillaRuta.LongUbicacionEntrega" type="text" step="any"
                            id="longUbicacionEntrega" class="form-control" required>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <vs-button :loading="isCreating" type="submit" animation-type="vertical">
                    Guardar Plantilla de Ruta
                    <template #animate> <i class="fas fa-plus-circle"></i></template>
                </vs-button>
            </div>
        </form>
        <DataTableButtons :items="plantillasRutas" :headers="headerPlantillaPuntosEntrega" :keys="plantillasRutasKeys"
            :actions1="showNotaAnexo" button1="fa-solid fa-pen" titleButton1="Editar punto de entrega"
            tableId="datatable_PlantillaRutaModal" />
    </card>
</template>

<script>
import DataTableButtons from "../../../components/Datatable/DatatableButtons.vue"
export default {
    name: 'PlantillaRutaModal',
    data() {
        return {
            isCreating: false,
            plantillaRuta: {
                LugarEntrega: '',
                LatUbicacionEntrega: null,
                LongUbicacionEntrega: null,
                EmpresaId: null
            },
            plantillasRutas: [],
            headerPlantillaPuntosEntrega: ["Nombre Punto", "Latitud", "Longitud"],
            plantillasRutasKeys: ["lugarEntrega", "latUbicacionEntrega", "longUbicacionEntrega"],
        }
    },
    components: {
        DataTableButtons
    },
    async mounted() {
        const persona = JSON.parse(localStorage.getItem("setPersonaAct"));
        this.plantillaRuta.EmpresaId = persona.empresa.id;
        await this.getPuntosEntregaData();
    },
    methods: {
        async guardarPlantillaRuta() {
            try {
                this.isCreating = true;
                const respuesta = await this.$store.dispatch("hl_post", {
                    path: "EntregasApp/CreatePlantillaRutas",
                    data: this.plantillaRuta
                });
                if (respuesta.success) {
                    this.$vs.notification({
                        progress: "auto",
                        icon: `<i class='fas fa-circle-check'></i>`,
                        color: 'success',
                        position: 'top-right',
                        title: 'Éxito',
                        text: 'Plantilla de ruta guardada correctamente'
                    });
                    this.$emit('plantilla-ruta-agregada');
                    this.$bvModal.hide('modalPlantillaRuta');
                }
            } catch (error) {
                console.error("Error al guardar la plantilla de ruta:", error);
                this.$vs.notification({
                    progress: "auto",
                    icon: `<i class='fas fa-exclamation-circle'></i>`,
                    color: 'danger',
                    position: 'top-right',
                    title: 'Error',
                    text: 'No se pudo guardar la plantilla de ruta'
                });
            } finally {
                this.isCreating = false;
            }
        },
        async getPuntosEntregaData() {
            console.log("Este es el id de la empresa", this.plantillaRuta.EmpresaId)
            try {

                const path = `EntregasApp/GetPlantillasRutasByEmpresa/${this.plantillaRuta.EmpresaId}`;
                const response = await this.$store.dispatch("get", { path });

                if (response && response.data) {
                    this.plantillasRutas = response.data;
                    console.log("Plantillas de entrega obtenidas:", this.plantillasRutas);
                } else {
                    console.error("Error al obtener las plantillas de entrega. Respuesta vacía.");
                    alert("Error al obtener las plantillas de entrega.");
                }
            } catch (error) {
                console.error("Hubo un error al hacer la solicitud:", error);
                alert("Hubo un error al obtener las plantillas de entrega.");
            }
        },
    }
}
</script>

<style scoped>
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");
</style>
