var render = function render(){var _vm=this,_c=_vm._self._c;return _c('HXContentCard',{attrs:{"title":"Registro Servicios"}},[_c('div',{staticClass:"px-5 py-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-8"},[_c('CardTable',{attrs:{"data":_vm.dataCards}})],1),_c('div',{staticClass:"col-12 col-md-4 col-lg-4 d-flex justify-content-end"},[_c('div',{staticClass:"w-auto"},[_c('BtnAddCardTable',{directives:[{name:"b-modal",rawName:"v-b-modal.modalPuntoEntrega",modifiers:{"modalPuntoEntrega":true}}],staticClass:"w-auto",attrs:{"text":"Crear nueva ruta"}})],1),_c('div',{staticClass:"w-auto ml-3"},[_c('BtnAddCardTable',{directives:[{name:"b-modal",rawName:"v-b-modal.modalPlantillaRuta",modifiers:{"modalPlantillaRuta":true}}],staticClass:"w-auto",attrs:{"text":"Crear puntos de entrega"}})],1)])])]),_c('div',{staticClass:"px-4 mt-3"},[_c('table',{staticClass:"table table-bordered table-responsive-md table-striped text-center",attrs:{"id":"datatable_registroServicios"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Ruta")]),_c('th',[_vm._v("Descripción")]),_c('th',[_vm._v("Conductor")]),_c('th',[_vm._v("Placa")]),_c('th',[_vm._v("Distancia Total")]),_c('th',[_vm._v("Tiempo Total")]),_c('th',[_vm._v("Fecha y Hora Ruta")]),_c('th',[_vm._v("Estado")]),_c('th',[_vm._v("Acciones")])])]),_c('tbody',_vm._l((_vm.listaItems),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.nombreRuta))]),_c('td',[_vm._v(_vm._s(item.descripcionRuta))]),_c('td',[_vm._v(_vm._s(item.nombres + ' ' + item.apellidos))]),_c('td',[_vm._v(_vm._s(item.placa))]),_c('td',[_vm._v(_vm._s(item.distanciaTotalRecorrida))]),_c('td',[_vm._v(_vm._s(item.tiempoTotalRecorrido))]),_c('td',[_vm._v(_vm._s(_vm._f("timeSince")(item.fechaHoraInicioRuta)))]),_c('td',[_vm._v(_vm._s(item.estadoRuta))]),_c('td',{staticClass:"d-flex"},[_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              title: 'Añadir puntos de entrega a la ruta',
              placement: 'top',
              customClass: 'font_tooltip',
            }),expression:"{\n              title: 'Añadir puntos de entrega a la ruta',\n              placement: 'top',\n              customClass: 'font_tooltip',\n            }"},{name:"b-modal",rawName:"v-b-modal.modalPlanificarRuta",modifiers:{"modalPlanificarRuta":true}}],attrs:{"success":"","icon":""},on:{"click":function($event){return _vm.obtenerId(item.id)}}},[_c('i',{staticClass:"fa-solid fa-truck-ramp-box"})]),_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              title: 'Ver ruta en Google Maps',
              placement: 'top',
              customClass: 'font_tooltip',
            }),expression:"{\n              title: 'Ver ruta en Google Maps',\n              placement: 'top',\n              customClass: 'font_tooltip',\n            }"}],attrs:{"icon":""},on:{"click":function($event){return _vm.verRutaEnMapa(item.id)}}},[_c('i',{staticClass:"fas fa-map-marked-alt"})]),_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              title: 'Ver Puntos de Entrega',
              placement: 'top',
              customClass: 'font_tooltip',
            }),expression:"{\n              title: 'Ver Puntos de Entrega',\n              placement: 'top',\n              customClass: 'font_tooltip',\n            }"}],attrs:{"success":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.openPuntosEntregaModal(item.id)}}},[_c('i',{staticClass:"fas fa-map-marker-alt"})])],1)])}),0)]),(_vm.selectedItem)?_c('b-modal',{attrs:{"title":"Puntos de Entrega","id":"modalPuntosEntrega","size":"xl","hide-footer":""}},[_c('table',{staticClass:"table table-bordered table-responsive-md table-striped text-center",attrs:{"id":"datatable_registroPuntosEntrega"}},[_c('thead',[_c('tr',_vm._l((_vm.headerPuntosEntrega),function(header){return _c('th',{key:header},[_vm._v(_vm._s(header))])}),0)]),_c('tbody',_vm._l((_vm.listaPuntosEntrega),function(item){return _c('tr',{key:item.id},[_vm._l((_vm.puntosEntregaKeys),function(key){return _c('td',{key:key},[_vm._v(_vm._s(item[key]))])}),_c('td',{staticClass:"d-flex"},[_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                title: 'Abrir coordenadas conductor',
                placement: 'top',
                customClass: 'font_tooltip',
              }),expression:"{\n                title: 'Abrir coordenadas conductor',\n                placement: 'top',\n                customClass: 'font_tooltip',\n              }"}],attrs:{"success":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.verCoordenadasConductor(item)}}},[_c('i',{staticClass:"fa-solid fa-truck-ramp-box"})])],1)],2)}),0)])]):_vm._e()],1),_c('b-modal',{attrs:{"title":"Nueva Plantilla de Ruta","id":"modalPlantillaRuta","size":"lg","hide-footer":""}},[_c('PlantillaRutaModal',{on:{"plantilla-ruta-agregada":_vm.actualizarDatos}})],1),_c('b-modal',{staticClass:"text-uppercase",attrs:{"title":"CREAR UNA NUEVA RUTA","id":"modalPuntoEntrega","size":"lg","hide-footer":""}},[_c('CrearRutaModal',{on:{"punto-entrega-agregado":_vm.actualizarDatos}})],1),_c('b-modal',{attrs:{"title":"Planificar ruta","id":"modalPlanificarRuta","size":"xl","hide-footer":""}},[_c('PlanificadorRutas',{attrs:{"rutaIdObtener":_vm.rutaId}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }