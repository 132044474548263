<template>
  <HXContentCard title="Registro Servicios">
    <div class="px-5 py-4">
      <div class="row">
        <!-- Columna para el CardTable -->
        <div class="col-12 col-md-6 col-lg-8">
          <CardTable :data="dataCards" />
        </div>
        <!-- Columna para los botones -->
        <div class="col-12 col-md-4 col-lg-4 d-flex justify-content-end">
          <!-- Primer botón -->
          <div class="w-auto">
            <BtnAddCardTable v-b-modal.modalPuntoEntrega text="Crear nueva ruta" class="w-auto" />
          </div>
          <!-- Segundo botón con margen izquierdo para separación -->
          <div class="w-auto ml-3">
            <BtnAddCardTable v-b-modal.modalPlantillaRuta text="Crear puntos de entrega" class="w-auto" />
          </div>
        </div>
      </div>
    </div>
    <!-- table registro servicios-->
    <div class="px-4 mt-3">
      <table id="datatable_registroServicios"
        class="table table-bordered table-responsive-md table-striped text-center">
        <thead>
          <tr>
            <th>Ruta</th>
            <th>Descripción</th>
            <th>Conductor</th>
            <th>Placa</th>
            <th>Distancia Total</th>
            <th>Tiempo Total</th>
            <th>Fecha y Hora Ruta</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item) in listaItems" :key="item.id">
            <td>{{ item.nombreRuta }}</td>
            <td>{{ item.descripcionRuta }}</td>
            <td>{{ item.nombres + ' ' + item.apellidos }}</td>
            <td>{{ item.placa }}</td>
            <td>{{ item.distanciaTotalRecorrida }}</td>
            <td>{{ item.tiempoTotalRecorrido }}</td>
            <td>{{ item.fechaHoraInicioRuta | timeSince }}</td>
            <td>{{ item.estadoRuta }}</td>
            <td class="d-flex">
              <vs-button success v-b-tooltip="{
                title: 'Añadir puntos de entrega a la ruta',
                placement: 'top',
                customClass: 'font_tooltip',
              }" icon v-b-modal.modalPlanificarRuta @click="obtenerId(item.id)">
                <i class="fa-solid fa-truck-ramp-box"></i>
              </vs-button>
              <vs-button v-b-tooltip="{
                title: 'Ver ruta en Google Maps',
                placement: 'top',
                customClass: 'font_tooltip',
              }" icon @click="verRutaEnMapa(item.id)">
                <i class="fas fa-map-marked-alt"></i>
              </vs-button>
              <vs-button success v-b-tooltip="{
                title: 'Ver Puntos de Entrega',
                placement: 'top',
                customClass: 'font_tooltip',
              }" icon @click.prevent="openPuntosEntregaModal(item.id)">
                <i class="fas fa-map-marker-alt"></i>
              </vs-button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Modal para puntos de entrega -->
      <b-modal title="Puntos de Entrega" id="modalPuntosEntrega" size="xl" hide-footer v-if="selectedItem">
        <table id="datatable_registroPuntosEntrega"
          class="table table-bordered table-responsive-md table-striped text-center">
          <thead>
            <tr>
              <th v-for="header in headerPuntosEntrega" :key="header">{{ header }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item) in listaPuntosEntrega" :key="item.id">
              <td v-for="key in puntosEntregaKeys" :key="key">{{ item[key] }}</td>
              <td class="d-flex">
                <vs-button success v-b-tooltip="{
                  title: 'Abrir coordenadas conductor',
                  placement: 'top',
                  customClass: 'font_tooltip',
                }"
                icon @click.prevent="verCoordenadasConductor(item)"
                >
                  <i class="fa-solid fa-truck-ramp-box"></i>
                </vs-button>
              </td>
            </tr>
          </tbody>
        </table>
      </b-modal>
    </div>
    <!-- modal plantilla ruta -->
    <b-modal title="Nueva Plantilla de Ruta" id="modalPlantillaRuta" size="lg" hide-footer>
      <PlantillaRutaModal @plantilla-ruta-agregada="actualizarDatos" />
    </b-modal>
    <!-- modal punto entrega -->
    <b-modal title="CREAR UNA NUEVA RUTA" class="text-uppercase" id="modalPuntoEntrega" size="lg" hide-footer>
      <CrearRutaModal @punto-entrega-agregado="actualizarDatos" />
    </b-modal>
    <b-modal title="Planificar ruta" id="modalPlanificarRuta" size="xl" hide-footer>
      <PlanificadorRutas :rutaIdObtener="rutaId" />
    </b-modal>


  </HXContentCard>
</template>

<script>
import CardTable from "../../../components/cardTable/CardTable.vue";
import BtnAddCardTable from "../../../components/btnCardTable/btnAddCardTable.vue";
import PlantillaRutaModal from "./PlantillaRutaModal.vue";
import CrearRutaModal from "./CrearRutaModal.vue";
import PlanificadorRutas from "./PlanificadorRutas.vue";
import moment from "moment/moment";
import moment_timezone from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "registroServicios",
  components: {
    CardTable,
    BtnAddCardTable,
    PlantillaRutaModal,
    CrearRutaModal,
    PlanificadorRutas
  },
  data() {
    return {
      persona: {},
      dataTableInstance: null,
      listaItems: [],
      listaPuntosEntrega: [],
      headerPuntosEntrega: ["Nombre Punto", "Fecha y hora de inicio", "Fecha y Hora de entrega", "Latitud de la ubicación de Entrega", "Longitud de la Ubicación de Entrega", "opciones"],
      puntosEntregaKeys: ["lugarEntrega", "fechaHoraInicio", "fechaHoraEntrega", "latUbicacionEntrega", "longUbicacionEntrega"],
      dataCards: [
        {
          title: "Total Puntos de Entrega",
          value: () => this.listaItems.length,
          iconName: "map-marker-alt",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            this.dataTableInstance.search("").draw();
          },
          onDeactivate: () => {
            this.dataTableInstance.search("").draw();
          },
        },
      ],
      selectedItem: null, // ID del ítem seleccionado para el modal
      rutaId: null,
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.listaItems = [];
    await this.getData();

    if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
      window.$("#datatable_registroServicios").DataTable().destroy();
    }
    this.dataTableInstance = window
      .$("#datatable_registroServicios")
      .DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
    this.$isLoading(false);
  },
  methods: {

    verCoordenadasConductor(item) {
      console.log("ID del punto de entrega:", item);
      this.$router.push({
        name: 'DetallePuntoEntrega',
        params: { id: item.id }
      });
    },

    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "EntregasApp/GetPuntosEntregaGeneralesConPersonasByEmpresaId/" + this.persona.empresa.id,
        });
        console.log("ESTE ES EL LISTADO DE LAS RUTAS GENERADAS", res.data)
        if (res.success) {
          this.listaItems = res.data;
        } else {
          this.listaItems = [];
          console.log("No se encontraron puntos de entrega para esta empresa");
        }
      } catch (error) {
        this.listaItems = [];
        console.log("Error al obtener los datos:", error);
      }
    },
    async verDetallesRuta(id) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "EntregasApp/GetDetallesEntregaByRutaId/" + id,
        });
        console.log(res);
        if (res.success && res.data && res.data.length > 0) {
          this.ListarPuntosEntrega = res.data;
          console.log("ListarPuntosEntrega de la ruta:", this.ListarPuntosEntrega);
        } else {
          this.$bvToast.toast('No se encontraron detalles para esta ruta', {
            title: 'Error',
            variant: 'danger',
            solid: true
          });
        }
      } catch (error) {
        console.log("Error al obtener los detalles de la ruta:", error);
        this.$bvToast.toast('Error al obtener los detalles de la ruta', {
          title: 'Error',
          variant: 'danger',
          solid: true
        });
      }
    },
    async openPuntosEntregaModal(id) {
      this.selectedItem = id; // Almacena el ID seleccionado
      try {
        const res = await this.$store.dispatch("hl_get", {
          path: `EntregasApp/GetDetallesRutaById/${id}`,
        });
        if (res.success && res.data && res.data.length > 0) {
          this.listaPuntosEntrega = res.data; // Filtra los puntos de entrega por ID
          this.$bvModal.show("modalPuntosEntrega"); // Muestra el modal
          
        } else {
          this.$bvToast.toast("No se encontraron detalles para esta ruta", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      } catch (error) {
        console.error("Error al obtener los detalles de la ruta:", error);
        this.$bvToast.toast("Error al obtener los detalles de la ruta", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    actualizarDatos() {
      this.getData();
    },
    obtenerId(id) {
      console.log('Obteniendo ID para planificar ruta:', id);
      this.rutaId = id;
    },
    async verRutaEnMapa(id) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "EntregasApp/GetDetallesEntregaByRutaId/" + id,
        });
        if (res.success && res.data && res.data.length > 0) {
          this.abrirGoogleMaps(res.data);
        } else {
          this.$bvToast.toast('No se encontraron detalles para esta ruta', {
            title: 'Error',
            variant: 'danger',
            solid: true
          });
        }
      } catch (error) {
        console.error("Error al obtener los detalles de la ruta:", error);
        this.$bvToast.toast('Error al obtener los detalles de la ruta', {
          title: 'Error',
          variant: 'danger',
          solid: true
        });
      }
    },
    abrirGoogleMaps(rutas) {
      if (rutas.length === 0) return;

      let waypoints = rutas.map(ruta => `${ruta.latUbicacionActual},${ruta.longUbicacionActual}`);

      // Usar el primer punto como origen y el último como destino
      let origin = waypoints.shift();
      let destination = waypoints.pop();

      // Construir la URL de Google Maps
      let url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;

      // Agregar waypoints si hay más de dos puntos
      if (waypoints.length > 0) {
        url += `&waypoints=${waypoints.join('|')}`;
      }

      // Agregar modo de viaje
      url += '&travelmode=driving';

      // Abrir Google Maps en una nueva pestaña
      window.open(url, '_blank');
    }
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).locale("es").format('D MMM YYYY') : "No hay fecha registrada";
    },
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateInBogota.format("D MMM YYYY hh:mm A");
    },
  },
};
</script>

<style scoped></style>