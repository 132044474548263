<template>
    <div class="container my-4">
        <div class="bg-light  rounded mb-4 d-flex justify-content-center align-items-center" style="height: 400px;">
            <iframe class="rounded"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4923.898095885642!2d-74.11994331522916!3d4.70085942948783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9b51a4bdbd81%3A0x3c32170578887ea5!2sCentro%20Comercial%20Diverplaza!5e1!3m2!1ses-419!2sco!4v1732635287321!5m2!1ses-419!2sco"
                style="border:0; width: 100%; height: 100%" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <div class="card mb-4">
            <div class="card-body">
                <div class="mb-3 d-flex flex-column">
                    <span class="h3">Añadir puntos de entrega</span>
                    <MultiSelect v-model="plantillasElegidas" :options="plantillasRutas" valorGuardar="id"
                        valorVer="lugarEntrega" emptyList="¡Lo sentimos!. No tienes plantillas creadas."
                        :selectFirst="false" :allData="true" />
                </div>

                <div class="d-flex justify-content-between">
                    <button @click="limpiarPuntosEntrega()" class="btn btn-danger">Limpiar rutas</button>
                </div>
            </div>
        </div>

        <div class="alert alert-dark text-center font-weight-bold" role="alert" v-if="plantillasElegidas.length != 0">
            <span>Puntos de entrega totales: {{ plantillasElegidas.length }}</span>
        </div>


        <div class="card">
            <form ref="form">
                <ul class="list-group list-group-flush delivery-route">
                    <draggable v-model="plantillasElegidas" @start="drag = true" @end="drag = false"
                        v-bind="dragOptions" handle=".handle">
                        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                            <li v-for="(point, index) in puntosDeEntrega" :key="index"
                                class="list-group-item d-flex align-items-center justify-content-between route-point">
                                <div class="point-number handle">
                                    {{ index + 1 }}
                                </div>
                                <span class="col-2">{{ point.lugarEntrega }}</span>
                                <div class="col-8 bg-dark p-3 rounded " style="height: 15vh !important">
                                    <template v-if="!estimacionesTiempos[index]">
                                        <div class="d-flex flex-row h-100 justify-content-between">
                                            <div class=" d-flex flex-column justify-content-center">
                                                <label class="d-flex font-weight-bold flex-wrap ">Distancia:</label>
                                                <p class=" font-weight-normal">No se han consultado los puntos</p>
                                            </div>
                                            <div class=" d-flex flex-column justify-content-center ">
                                                <label class="d-flex font-weight-bold flex-wrap ">Tiempo (con
                                                    tráfico):</label>
                                                <p class=" font-weight-normal">No se han consultado los puntos</p>
                                            </div>
                                            <div class=" d-flex flex-column justify-content-center ">
                                                <label class="d-flex font-weight-bold flex-wrap ">Hora estimada de
                                                    llegada:</label>
                                                <p class=" font-weight-normal">No se han consultado los puntos</p>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="d-flex flex-row h-100 justify-content-between">
                                            <div class=" d-flex flex-column justify-content-center">
                                                <label class="d-flex font-weight-bold flex-wrap ">Distancia:</label>
                                                <p class=" font-weight-normal">{{ estimacionesTiempos[index].distancia
                                                    }}</p>
                                            </div>
                                            <div class=" d-flex flex-column justify-content-center ">
                                                <label class="d-flex font-weight-bold flex-wrap ">Tiempo (con
                                                    tráfico):</label>
                                                <p class=" font-weight-normal">{{
                                                    estimacionesTiempos[index].duracionConTrafico }}</p>
                                            </div>
                                            <div class=" d-flex flex-column justify-content-center ">
                                                <label class="d-flex font-weight-bold flex-wrap ">Hora estimada de
                                                    llegada:</label>
                                                <p class=" font-weight-normal">{{
                                                    estimacionesTiempos[index].horaEstimada }}</p>
                                            </div>
                                            <div class=" d-flex flex-column justify-content-center ">
                                                <label class="d-flex font-weight-bold flex-wrap ">Programar hora de
                                                    llegada:</label>
                                                <input :id="'deliveryDateTime' + index"
                                                    @change="actualizarFechaHoraEntrega(index, $event.target.value)"
                                                    type="datetime-local" class="form-control" required>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <vs-button @click="removePuntoEntrega(index)" class="text-danger bg-danger">
                                    <i class="fa-solid fa-trash"></i>
                                </vs-button>
                            </li>
                        </transition-group>
                    </draggable>
                </ul>
                <div class=" d-flex justify-content-around">
                    <vs-button @click.prevent="getUbicationApi()" class="text-danger bg-success">
                        <i class="fa-solid fa-location-crosshairs mr-2"></i>
                        Obtener tiempos aproximados
                    </vs-button>
                    <vs-button v-if="habilitarGuardado" @click="saveData()" class="text-danger bg-success"
                        type="submit">
                        <i class="fa-solid fa-truck"></i>
                        Guardar puntos de entrega
                    </vs-button>
                </div>
            </form>
        </div>




    </div>
</template>

<script>
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import draggable from "vuedraggable";
import Swal from "sweetalert2";

export default {
    name: 'PlanificadorRutas',
    data() {
        return {
            plantillasRutas: {},
            plantillasElegidas: {},
            originLatCoodenades: '4.701051911043765',
            originLongCoodenades: '-74.11504021789085',
            estimacionesTiempos: [],
            puntosDeEntrega: [],
            habilitarGuardado: false
        }
    },
    props: {
        rutaIdObtener: String
    },
    async mounted() {
        this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
        await this.getPuntosEntregaData();
    },
    components: {
        MultiSelect,
        draggable
    },
    watch: {
        // Sincronizar puntosDeEntrega cuando plantillasElegidas cambie
        plantillasElegidas: {
            handler(newVal) {
                this.puntosDeEntrega = newVal.map(point => ({
                    ...point,
                    fechaHoraInicio: new Date(),
                    fechaHoraEntrega: null,
                    tiempoDescarga: 'No estipulado',
                    estadoPuntoEntrega: 'No ha iniciado',
                    plantillaRutasId: point.id,
                    puntosEntregaGeneralesId: this.rutaIdObtener,
                    empresaId: this.persona.empresa.id, //
                }));
            },
            immediate: true
        }
    },
    methods: {

        async saveData() {
            const form = this.$refs.form;
            if (!form.checkValidity()) {
                Swal.fire({
                    title: "Completa todos los campos requeridos",
                    text: "Antes de continuar, por favor completa los campos",
                    icon: "warning",
                    confirmButtonColor: "#186bdd",
                    confirmButtonText: "Entendido",
                });
                return;
            }

            try {
                const puntosEntrega = this.puntosDeEntrega.map((point) => ({
                    distanciaRecorrida: point.distanciaRecorrida,
                    tiempoRecorrido: point.tiempoRecorrido,
                    fechaHoraInicio: point.fechaHoraInicio,
                    fechaHoraEntrega: point.fechaHoraEntrega,
                    tiempoDescarga: point.tiempoDescarga,
                    lugarEntrega: point.lugarEntrega,
                    estadoPuntoEntrega: point.estadoPuntoEntrega,
                    plantillaRutasId: point.plantillaRutasId,
                    puntosEntregaGeneralesId: point.puntosEntregaGeneralesId,
                    empresaId: point.empresaId,
                }));

                console.log("Datos a registrar:", puntosEntrega);

                // Envía todos los puntos en paralelo
                const results = await Promise.all(
                    puntosEntrega.map(async (punto) => {
                        try {
                            const response = await this.$store.dispatch("hl_post", {
                                path: "EntregasApp/CreatePuntosEntrega",
                                data: punto,
                            });

                            if (response.ok) {
                                const data = await response.json();
                                console.log("Punto de entrega registrado:", data);
                                return { success: true, data };
                            } else {
                                const errorData = await response.json();
                                console.error("Error en punto de entrega:", errorData);
                                return { success: false, error: errorData };
                            }
                        } catch (error) {
                            console.error("Error inesperado:", error);
                            return { success: false, error };
                        }
                    })
                );
                if (results) {
                    Swal.fire({
                        title: "¡Éxito!",
                        text: "Todos los puntos de entrega se registraron correctamente.",
                        icon: "success",
                        confirmButtonColor: "#186bdd",
                        confirmButtonText: "Entendido",
                    });
                }
            } catch (error) {
                console.error("Error crítico durante el registro:", error);
                Swal.fire({
                    title: "Error",
                    text: "Ocurrió un problema al procesar los registros. Inténtalo nuevamente.",
                    icon: "error",
                    confirmButtonColor: "#186bdd",
                    confirmButtonText: "Entendido",
                });
            }
        },




        actualizarFechaHoraEntrega(index, fecha) {
            this.puntosDeEntrega[index].fechaHoraEntrega = fecha;
        },
        removePuntoEntrega(index) {
            this.plantillasElegidas.splice(index, 1); // Elimina de plantillasElegidas
            this.puntosDeEntrega.splice(index, 1); // Elimina de puntosDeEntrega
        },

        async getPuntosEntregaData() {
            try {
                const path = `EntregasApp/GetPlantillasRutasByEmpresa/${this.persona.empresa.id}`;
                const response = await this.$store.dispatch("get", { path });

                if (response && response.data) {
                    this.plantillasRutas = response.data;
                    console.log("Plantillas de entrega obtenidas:", this.plantillasRutas);
                } else {
                    console.error("Error al obtener las plantillas de entrega. Respuesta vacía.");
                    alert("Error al obtener las plantillas de entrega.");
                }
            } catch (error) {
                console.error("Hubo un error al hacer la solicitud:", error);
                alert("Hubo un error al obtener las plantillas de entrega.");
            }
        },

        async getUbicationApi() {
            Swal.fire({
                title: "¿Has terminado de validar las rutas?",
                text: "Si no has terminado de validar las rutas y su orden, no podrás cambiar el orden después.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#186bdd",
                cancelButtonColor: "#F2135D",
                confirmButtonText: "Sí!",
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const apiKey = 'AIzaSyB94FC99-saCXOLmXgOJNRwo6CgE-t4d7s'; // Tu clave de API
                    let currentDateTime = new Date(); // Hora actual como punto de partida inicial

                    // Coordenadas iniciales
                    let originLat = this.originLatCoodenades;
                    let originLong = this.originLongCoodenades;

                    try {
                        for (let i = 0; i < this.plantillasElegidas.length; i++) {
                            const destinationLat = this.plantillasElegidas[i].latUbicacionEntrega;
                            const destinationLong = this.plantillasElegidas[i].longUbicacionEntrega;

                            console.log(`Ruta ${i + 1}:`);
                            console.log(`Origen: ${originLat},${originLong}`);
                            console.log(`Destino: ${destinationLat},${destinationLong}`);

                            // Construir la URL
                            const origin = `${originLat},${originLong}`;
                            const destination = `${destinationLat},${destinationLong}`;
                            const departureTimestamp = Math.floor(currentDateTime.getTime() / 1000); // Timestamp en segundos
                            const url = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&departure_time=${departureTimestamp}&traffic_model=best_guess&key=${apiKey}`;

                            // Hacer la solicitud
                            const response = await fetch(url);
                            const data = await response.json();

                            if (data.status === 'OK') {
                                const route = data.routes[0];
                                const leg = route.legs[0];

                                const distance = leg.distance.text; // Distancia
                                const durationInTraffic = leg.duration_in_traffic?.value || leg.duration.value; // Tiempo en segundos
                                const arrivalTime = new Date(currentDateTime.getTime() + durationInTraffic * 1000);

                                console.log(`Distancia: ${distance}`);
                                console.log(`Duración con tráfico: ${Math.floor(durationInTraffic / 60)} minutos`);
                                console.log(`Hora estimada de llegada: ${arrivalTime.toLocaleTimeString()}`);

                                this.estimacionesTiempos.push({
                                    distancia: distance,
                                    duracionConTrafico: `${Math.floor(durationInTraffic / 60)} minutos`,
                                    horaEstimada: arrivalTime.toLocaleTimeString(),
                                });

                                this.puntosDeEntrega = this.puntosDeEntrega.map((point, index) => ({
                                    ...point,
                                    distanciaRecorrida: this.estimacionesTiempos[index]?.distancia || null,
                                    tiempoRecorrido: this.estimacionesTiempos[index]?.duracionConTrafico || null,
                                }));

                                // Actualizar el tiempo acumulado para el siguiente punto
                                currentDateTime = arrivalTime;
                                this.habilitarGuardado = true;
                            } else {
                                console.error(`Error en la solicitud para la ruta ${i + 1}:`, data.error_message);
                            }

                            // Actualizar origen para el siguiente punto
                            originLat = destinationLat;
                            originLong = destinationLong;
                        }
                    } catch (error) {
                        console.error('Error en la solicitud:', error);
                    }
                }
            });
        },
        limpiarPuntosEntrega() {
            this.puntosDeEntrega = [];
            this.plantillasElegidas = [];
            this.getPuntosEntregaData();
            this.habilitarGuardado = false;
        }

    }
}
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");

.btn:active {
    transform: scale(0.98);
}

.delivery-route {
    position: relative;
    background: #212529;
}

.route-point {
    position: relative;
    padding: 1rem !important;
    z-index: 0;
}

.point-number {
    background: #198754;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    font-weight: bold;
    font-size: 1.3rem !important;
    position: relative;
    z-index: 2;
}

/* Connecting line using pseudo-element */
.route-point:not(:first-child, :last-child) .point-number::after {
    content: '';
    position: absolute;
    top: -56px;
    left: 50%;
    width: 8px;
    height: calc(100% + 7.7rem);
    /* Height of list item + padding */
    background-color: #198754;
    z-index: -1;
    transform: translateX(-50%);
}

.route-point:first-child .point-number::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: calc(100% + 2.7rem);
    /* Height of list item + padding */
    background-color: #198754;
    z-index: -1;
    transform: translateX(-50%);
}

.route-point:last-child .point-number::after {
    content: '';
    position: absolute;
    top: -84%;
    left: 50%;
    width: 8px;
    height: calc(100% + 1.7rem);
    /* Height of list item + padding */
    background-color: #198754;
    z-index: -1;
    transform: translateX(-50%);
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.handle {
    cursor: pointer !important;
}
</style>